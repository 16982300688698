import { object, string, InferType } from 'yup'

import { MedicationSchema } from 'schemas/MedicalHistory'
import { Medication, MedicationV2 } from 'types/PrivateData/MedicalHistory/Medication'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  memberId: string().required(),
  medication: MedicationSchema.required(),
})

export type Request = InferType<typeof Schema>

export const url = ({ memberId }: Request) => `/v1/history/personal/${memberId}/medications`

export const getBody = (params: Request) => {
  const medication = MedicationSchema.cast(params.medication, { stripUnknown: true })

  return { medications: [medication] }
}

export type SuccessfulResponse = {
  medications: (Medication | MedicationV2)[]
}
