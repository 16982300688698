// THIS FILE WAS GENERATED AUTOMATICALLY, DO NOT CHANGE IT!

import * as deleteAllergies from '../v1/MemberRecords/delete-allergies'
import * as deleteMedications from '../v1/MemberRecords/delete-medications'
import * as getPossibleConditions from '../v1/MemberRecords/get-possible-conditions'
import * as postAllergies from '../v1/MemberRecords/post-allergies'
import * as postFamilyHistory from '../v1/MemberRecords/post-family-history'
import * as postMedications from '../v1/MemberRecords/post-medications'
import * as putAllergies from '../v1/MemberRecords/put-allergies'
import * as putFamilyHistory from '../v1/MemberRecords/put-family-history'
import * as putMedications from '../v1/MemberRecords/put-medications'
import * as putStudentInfo from '../v1/MemberRecords/put-student-info'
import * as getPolicies from '../v1/Policies/get-policies'
import * as getPolicy from '../v1/Policies/get-policy'
import * as deleteVisitNote from '../v1/VisitNotes/delete-visit-note'
import * as postVisitNote from '../v1/VisitNotes/post-visit-note'
import * as putVisitNote from '../v1/VisitNotes/put-visit-note'
import * as deleteVisitReferral from '../v1/delete-visit-referral'
import * as getCancellationReasons from '../v1/get-cancellation-reasons'
import * as getCareProtocolVersion from '../v1/get-care-protocol-version'
import * as getCareProtocols from '../v1/get-care-protocols'
import * as getChat from '../v1/get-chat'
import * as getClientGroupServices from '../v1/get-client-group-services'
import * as getClient from '../v1/get-client'
import * as getForms from '../v1/get-forms'
import * as getMbcScores from '../v1/get-mbc-scores'
import * as getMe from '../v1/get-me'
import * as getMedication from '../v1/get-medication'
import * as getMedication2 from '../v1/get-medication2'
import * as getMedications from '../v1/get-medications'
import * as getMedications2 from '../v1/get-medications2'
import * as getMemberPrivateData from '../v1/get-member-private-data'
import * as getMemberServices from '../v1/get-member-services'
import * as getMemberVisitImage from '../v1/get-member-visit-image'
import * as getMemberVisitImages from '../v1/get-member-visit-images'
import * as getMemberVisitThumbImage from '../v1/get-member-visit-thumb-image'
import * as getMember from '../v1/get-member'
import * as getPendingVisit from '../v1/get-pending-visit'
import * as getPendingVisits from '../v1/get-pending-visits'
import * as getPharmacy from '../v1/get-pharmacy'
import * as getPhoneCallToken from '../v1/get-phone-call-token'
import * as getPrescription from '../v1/get-prescription'
import * as getProviderAvailability from '../v1/get-provider-availability'
import * as getProviderDispenseUnits from '../v1/get-provider-dispense-units'
import * as getProviderMembers from '../v1/get-provider-members'
import * as getProviderPastVisits from '../v1/get-provider-past-visits'
import * as getProviderPayment from '../v1/get-provider-payment'
import * as getProviderPayments from '../v1/get-provider-payments'
import * as getProviderQuestionnaireLatest from '../v1/get-provider-questionnaire-latest'
import * as getProviderQuestionnaire from '../v1/get-provider-questionnaire'
import * as getProviderSchedulings from '../v1/get-provider-schedulings'
import * as getProviderVisit from '../v1/get-provider-visit'
import * as getProviderVisitsQueue from '../v1/get-provider-visits-queue'
import * as getProvider from '../v1/get-provider'
import * as getRxChangePharmacy from '../v1/get-rx-change-pharmacy'
import * as getRxChangePrescriptionDetails from '../v1/get-rx-change-prescription-details'
import * as getRxChanges from '../v1/get-rx-changes'
import * as getSearchDischargeInstructions from '../v1/get-search-discharge-instructions'
import * as getSearchIcdCodes from '../v1/get-search-icd-codes'
import * as getSearchPharmacies from '../v1/get-search-pharmacies'
import * as getStartVideoCallV2 from '../v1/get-start-video-call-v2'
import * as getTransmissionErrors from '../v1/get-transmission-errors'
import * as getVisitEscalations from '../v1/get-visit-escalations'
import * as getVisits from '../v1/get-visits'
import * as getVisits2 from '../v1/get-visits2'
import * as postAcknowledgeTransmissionErrors2 from '../v1/post-acknowledge-transmission-errors2'
import * as postAuthChallenge from '../v1/post-auth-challenge'
import * as postChatMessage from '../v1/post-chat-message'
import * as postConference from '../v1/post-conference'
import * as postConferencesCallback from '../v1/post-conferences-callback'
import * as postFillPrescriptions from '../v1/post-fill-prescriptions'
import * as postFollowupVisit from '../v1/post-followup-visit'
import * as postForgotPassword from '../v1/post-forgot-password'
import * as postGenerateMfa from '../v1/post-generate-mfa'
import * as postLogin from '../v1/post-login'
import * as postMemberPrivateData from '../v1/post-member-private-data'
import * as postRefreshToken from '../v1/post-refresh-token'
import * as postResendVerificationEmail from '../v1/post-resend-verification-email'
import * as postResetPassword from '../v1/post-reset-password'
import * as postRx2 from '../v1/post-rx2'
import * as postSupportTicket from '../v1/post-support-ticket'
import * as postVerifyMfa from '../v1/post-verify-mfa'
import * as postVisitEscalation from '../v1/post-visit-escalation'
import * as putChangeRequestApprove from '../v1/put-change-request-approve'
import * as putChangeRequestDeny from '../v1/put-change-request-deny'
import * as putClaimPendingVisit from '../v1/put-claim-pending-visit'
import * as putCompleteVisit from '../v1/put-complete-visit'
import * as putPendingVisitStatus from '../v1/put-pending-visit-status'
import * as putPhoneVerify from '../v1/put-phone-verify'
import * as putProviderNotifications from '../v1/put-provider-notifications'
import * as putProviderSchedule from '../v1/put-provider-schedule'
import * as putProviderSchedulingsAccept from '../v1/put-provider-schedulings-accept'
import * as putProviderSchedulingsCancel from '../v1/put-provider-schedulings-cancel'
import * as putProviderSchedulingsReject from '../v1/put-provider-schedulings-reject'
import * as putProvider from '../v1/put-provider'
import * as putPushRegistration from '../v1/put-push-registration'
import * as putReadChatMessage from '../v1/put-read-chat-message'
import * as putRxCancel from '../v1/put-rx-cancel'
import * as putSafetyPlan from '../v1/put-safety-plan'
import * as putStartPendingVisit from '../v1/put-start-pending-visit'
import * as putUpdatePassword from '../v1/put-update-password'
import * as putVerificationSend from '../v1/put-verification-send'
import * as putVisitAssessment from '../v1/put-visit-assessment'
import * as putVisitDischarge from '../v1/put-visit-discharge'
import * as putVisitDx from '../v1/put-visit-dx'
import * as putVisitPharmacy from '../v1/put-visit-pharmacy'
import * as putVisitReferral from '../v1/put-visit-referral'
import * as putVisit from '../v1/put-visit'
import * as postMedicationsV2 from '../v1/v2/MemberRecords/post-medications-v2'
import * as putMedicalHistoryV2 from '../v1/v2/MemberRecords/put-medical-history-v2'
import * as putMedicationsV2 from '../v1/v2/MemberRecords/put-medications-v2'
import * as getMemberVisitImageV2 from '../v1/v2/images/get-member-visit-image-v2'
import * as getMemberVisitImagesV2 from '../v1/v2/images/get-member-visit-images-v2'
import * as getMemberVisitThumbImageV2 from '../v1/v2/images/get-member-visit-thumb-image-v2'
import * as deleteScheduleSlot from '../v1/v2/schedules/delete-schedule-slot'
import * as getProviderSchedule from '../v1/v2/schedules/get-provider-schedule'
import * as getProviderSchedules from '../v1/v2/schedules/get-provider-schedules'
import * as postProviderSchedule from '../v1/v2/schedules/post-provider-schedule'
import * as postScheduleSlot from '../v1/v2/schedules/post-schedule-slot'
import * as putScheduleSlot from '../v1/v2/schedules/put-schedule-slot'

import queryDefaults from './queryDefaults'

const LOCAL_QUERY_DEFAULTS = {
  baseURL: '',
  responseType: 'json',
  getBody: () => null,
  shouldNotLogoutOn403Error: false,
} as const

const QUERY_DEFAULTS = {
  ...LOCAL_QUERY_DEFAULTS,
  ...queryDefaults,
} as const

type Query<Request> = {
  url: (request: Request | never) => string
  method: 'get' | 'post' | 'put' | 'delete'
  getBody: (request: Request) => Awaited<object | null>
  responseType: 'json' | 'blob' | 'text'
  baseURL: string
  shouldNotLogoutOn403Error: boolean
  [x: string]: any
}

const ApiQueries = {
  deleteAllergies: { ...QUERY_DEFAULTS, ...deleteAllergies, method: 'delete' } satisfies Query<any>,
  deleteMedications: { ...QUERY_DEFAULTS, ...deleteMedications, method: 'delete' } satisfies Query<any>,
  getPossibleConditions: { ...QUERY_DEFAULTS, ...getPossibleConditions, method: 'get' } satisfies Query<any>,
  postAllergies: { ...QUERY_DEFAULTS, ...postAllergies, method: 'post' } satisfies Query<any>,
  postFamilyHistory: { ...QUERY_DEFAULTS, ...postFamilyHistory, method: 'post' } satisfies Query<any>,
  postMedications: { ...QUERY_DEFAULTS, ...postMedications, method: 'post' } satisfies Query<postMedications.Request>,
  putAllergies: { ...QUERY_DEFAULTS, ...putAllergies, method: 'put' } satisfies Query<any>,
  putFamilyHistory: { ...QUERY_DEFAULTS, ...putFamilyHistory, method: 'put' } satisfies Query<any>,
  putMedications: { ...QUERY_DEFAULTS, ...putMedications, method: 'put' } satisfies Query<putMedications.Request>,
  putStudentInfo: { ...QUERY_DEFAULTS, ...putStudentInfo, method: 'put' } satisfies Query<any>,
  getPolicies: { ...QUERY_DEFAULTS, ...getPolicies, method: 'get' } satisfies Query<any>,
  getPolicy: { ...QUERY_DEFAULTS, ...getPolicy, method: 'get' } satisfies Query<any>,
  deleteVisitNote: { ...QUERY_DEFAULTS, ...deleteVisitNote, method: 'delete' } satisfies Query<any>,
  postVisitNote: { ...QUERY_DEFAULTS, ...postVisitNote, method: 'post' } satisfies Query<any>,
  putVisitNote: { ...QUERY_DEFAULTS, ...putVisitNote, method: 'put' } satisfies Query<any>,
  deleteVisitReferral: { ...QUERY_DEFAULTS, ...deleteVisitReferral, method: 'delete' } satisfies Query<any>,
  getCancellationReasons: {
    ...QUERY_DEFAULTS,
    ...getCancellationReasons,
    method: 'get',
  } satisfies Query<getCancellationReasons.Request>,
  getCareProtocolVersion: { ...QUERY_DEFAULTS, ...getCareProtocolVersion, method: 'get' } satisfies Query<any>,
  getCareProtocols: { ...QUERY_DEFAULTS, ...getCareProtocols, method: 'get' } satisfies Query<any>,
  getChat: { ...QUERY_DEFAULTS, ...getChat, method: 'get' } satisfies Query<any>,
  getClientGroupServices: { ...QUERY_DEFAULTS, ...getClientGroupServices, method: 'get' } satisfies Query<any>,
  getClient: { ...QUERY_DEFAULTS, ...getClient, method: 'get' } satisfies Query<any>,
  getForms: { ...QUERY_DEFAULTS, ...getForms, method: 'get' } satisfies Query<any>,
  getMbcScores: { ...QUERY_DEFAULTS, ...getMbcScores, method: 'get' } satisfies Query<any>,
  getMe: { ...QUERY_DEFAULTS, ...getMe, method: 'get' } satisfies Query<any>,
  getMedication: { ...QUERY_DEFAULTS, ...getMedication, method: 'get' } satisfies Query<any>,
  getMedication2: { ...QUERY_DEFAULTS, ...getMedication2, method: 'get' } satisfies Query<getMedication2.Request>,
  getMedications: { ...QUERY_DEFAULTS, ...getMedications, method: 'get' } satisfies Query<any>,
  getMedications2: { ...QUERY_DEFAULTS, ...getMedications2, method: 'get' } satisfies Query<getMedications2.Request>,
  getMemberPrivateData: { ...QUERY_DEFAULTS, ...getMemberPrivateData, method: 'get' } satisfies Query<any>,
  getMemberServices: { ...QUERY_DEFAULTS, ...getMemberServices, method: 'get' } satisfies Query<any>,
  getMemberVisitImage: {
    ...QUERY_DEFAULTS,
    ...getMemberVisitImage,
    method: 'get',
  } satisfies Query<getMemberVisitImage.Request>,
  getMemberVisitImages: {
    ...QUERY_DEFAULTS,
    ...getMemberVisitImages,
    method: 'get',
  } satisfies Query<getMemberVisitImages.Request>,
  getMemberVisitThumbImage: {
    ...QUERY_DEFAULTS,
    ...getMemberVisitThumbImage,
    method: 'get',
  } satisfies Query<getMemberVisitThumbImage.Request>,
  getMember: { ...QUERY_DEFAULTS, ...getMember, method: 'get' } satisfies Query<any>,
  getPendingVisit: { ...QUERY_DEFAULTS, ...getPendingVisit, method: 'get' } satisfies Query<any>,
  getPendingVisits: { ...QUERY_DEFAULTS, ...getPendingVisits, method: 'get' } satisfies Query<any>,
  getPharmacy: { ...QUERY_DEFAULTS, ...getPharmacy, method: 'get' } satisfies Query<any>,
  getPhoneCallToken: {
    ...QUERY_DEFAULTS,
    ...getPhoneCallToken,
    method: 'get',
  } satisfies Query<getPhoneCallToken.Request>,
  getPrescription: { ...QUERY_DEFAULTS, ...getPrescription, method: 'get' } satisfies Query<any>,
  getProviderAvailability: {
    ...QUERY_DEFAULTS,
    ...getProviderAvailability,
    method: 'get',
  } satisfies Query<getProviderAvailability.Request>,
  getProviderDispenseUnits: { ...QUERY_DEFAULTS, ...getProviderDispenseUnits, method: 'get' } satisfies Query<any>,
  getProviderMembers: {
    ...QUERY_DEFAULTS,
    ...getProviderMembers,
    method: 'get',
  } satisfies Query<getProviderMembers.Request>,
  getProviderPastVisits: { ...QUERY_DEFAULTS, ...getProviderPastVisits, method: 'get' } satisfies Query<any>,
  getProviderPayment: { ...QUERY_DEFAULTS, ...getProviderPayment, method: 'get' } satisfies Query<any>,
  getProviderPayments: { ...QUERY_DEFAULTS, ...getProviderPayments, method: 'get' } satisfies Query<any>,
  getProviderQuestionnaireLatest: {
    ...QUERY_DEFAULTS,
    ...getProviderQuestionnaireLatest,
    method: 'get',
  } satisfies Query<any>,
  getProviderQuestionnaire: { ...QUERY_DEFAULTS, ...getProviderQuestionnaire, method: 'get' } satisfies Query<any>,
  getProviderSchedulings: { ...QUERY_DEFAULTS, ...getProviderSchedulings, method: 'get' } satisfies Query<any>,
  getProviderVisit: { ...QUERY_DEFAULTS, ...getProviderVisit, method: 'get' } satisfies Query<any>,
  getProviderVisitsQueue: { ...QUERY_DEFAULTS, ...getProviderVisitsQueue, method: 'get' } satisfies Query<any>,
  getProvider: { ...QUERY_DEFAULTS, ...getProvider, method: 'get' } satisfies Query<any>,
  getRxChangePharmacy: {
    ...QUERY_DEFAULTS,
    ...getRxChangePharmacy,
    method: 'get',
  } satisfies Query<getRxChangePharmacy.Request>,
  getRxChangePrescriptionDetails: {
    ...QUERY_DEFAULTS,
    ...getRxChangePrescriptionDetails,
    method: 'get',
  } satisfies Query<getRxChangePrescriptionDetails.Request>,
  getRxChanges: { ...QUERY_DEFAULTS, ...getRxChanges, method: 'get' } satisfies Query<any>,
  getSearchDischargeInstructions: {
    ...QUERY_DEFAULTS,
    ...getSearchDischargeInstructions,
    method: 'get',
  } satisfies Query<any>,
  getSearchIcdCodes: { ...QUERY_DEFAULTS, ...getSearchIcdCodes, method: 'get' } satisfies Query<any>,
  getSearchPharmacies: { ...QUERY_DEFAULTS, ...getSearchPharmacies, method: 'get' } satisfies Query<any>,
  getStartVideoCallV2: { ...QUERY_DEFAULTS, ...getStartVideoCallV2, method: 'get' } satisfies Query<any>,
  getTransmissionErrors: { ...QUERY_DEFAULTS, ...getTransmissionErrors, method: 'get' } satisfies Query<any>,
  getVisitEscalations: {
    ...QUERY_DEFAULTS,
    ...getVisitEscalations,
    method: 'get',
  } satisfies Query<getVisitEscalations.Request>,
  getVisits: { ...QUERY_DEFAULTS, ...getVisits, method: 'get' } satisfies Query<any>,
  getVisits2: { ...QUERY_DEFAULTS, ...getVisits2, method: 'get' } satisfies Query<any>,
  postAcknowledgeTransmissionErrors2: {
    ...QUERY_DEFAULTS,
    ...postAcknowledgeTransmissionErrors2,
    method: 'post',
  } satisfies Query<postAcknowledgeTransmissionErrors2.Request>,
  postAuthChallenge: {
    ...QUERY_DEFAULTS,
    ...postAuthChallenge,
    method: 'post',
  } satisfies Query<postAuthChallenge.Request>,
  postChatMessage: { ...QUERY_DEFAULTS, ...postChatMessage, method: 'post' } satisfies Query<any>,
  postConference: { ...QUERY_DEFAULTS, ...postConference, method: 'post' } satisfies Query<any>,
  postConferencesCallback: {
    ...QUERY_DEFAULTS,
    ...postConferencesCallback,
    method: 'post',
  } satisfies Query<postConferencesCallback.Request>,
  postFillPrescriptions: { ...QUERY_DEFAULTS, ...postFillPrescriptions, method: 'post' } satisfies Query<any>,
  postFollowupVisit: { ...QUERY_DEFAULTS, ...postFollowupVisit, method: 'post' } satisfies Query<any>,
  postForgotPassword: { ...QUERY_DEFAULTS, ...postForgotPassword, method: 'post' } satisfies Query<any>,
  postGenerateMfa: { ...QUERY_DEFAULTS, ...postGenerateMfa, method: 'post' } satisfies Query<any>,
  postLogin: { ...QUERY_DEFAULTS, ...postLogin, method: 'post' } satisfies Query<any>,
  postMemberPrivateData: { ...QUERY_DEFAULTS, ...postMemberPrivateData, method: 'post' } satisfies Query<any>,
  postRefreshToken: { ...QUERY_DEFAULTS, ...postRefreshToken, method: 'post' } satisfies Query<any>,
  postResendVerificationEmail: {
    ...QUERY_DEFAULTS,
    ...postResendVerificationEmail,
    method: 'post',
  } satisfies Query<any>,
  postResetPassword: { ...QUERY_DEFAULTS, ...postResetPassword, method: 'post' } satisfies Query<any>,
  postRx2: { ...QUERY_DEFAULTS, ...postRx2, method: 'post' } satisfies Query<postRx2.Request>,
  postSupportTicket: { ...QUERY_DEFAULTS, ...postSupportTicket, method: 'post' } satisfies Query<any>,
  postVerifyMfa: { ...QUERY_DEFAULTS, ...postVerifyMfa, method: 'post' } satisfies Query<postVerifyMfa.Request>,
  postVisitEscalation: {
    ...QUERY_DEFAULTS,
    ...postVisitEscalation,
    method: 'post',
  } satisfies Query<postVisitEscalation.Request>,
  putChangeRequestApprove: { ...QUERY_DEFAULTS, ...putChangeRequestApprove, method: 'put' } satisfies Query<any>,
  putChangeRequestDeny: { ...QUERY_DEFAULTS, ...putChangeRequestDeny, method: 'put' } satisfies Query<any>,
  putClaimPendingVisit: { ...QUERY_DEFAULTS, ...putClaimPendingVisit, method: 'put' } satisfies Query<any>,
  putCompleteVisit: { ...QUERY_DEFAULTS, ...putCompleteVisit, method: 'put' } satisfies Query<any>,
  putPendingVisitStatus: { ...QUERY_DEFAULTS, ...putPendingVisitStatus, method: 'put' } satisfies Query<any>,
  putPhoneVerify: { ...QUERY_DEFAULTS, ...putPhoneVerify, method: 'put' } satisfies Query<any>,
  putProviderNotifications: { ...QUERY_DEFAULTS, ...putProviderNotifications, method: 'put' } satisfies Query<any>,
  putProviderSchedule: { ...QUERY_DEFAULTS, ...putProviderSchedule, method: 'put' } satisfies Query<any>,
  putProviderSchedulingsAccept: {
    ...QUERY_DEFAULTS,
    ...putProviderSchedulingsAccept,
    method: 'put',
  } satisfies Query<any>,
  putProviderSchedulingsCancel: {
    ...QUERY_DEFAULTS,
    ...putProviderSchedulingsCancel,
    method: 'put',
  } satisfies Query<any>,
  putProviderSchedulingsReject: {
    ...QUERY_DEFAULTS,
    ...putProviderSchedulingsReject,
    method: 'put',
  } satisfies Query<any>,
  putProvider: { ...QUERY_DEFAULTS, ...putProvider, method: 'put' } satisfies Query<any>,
  putPushRegistration: { ...QUERY_DEFAULTS, ...putPushRegistration, method: 'put' } satisfies Query<any>,
  putReadChatMessage: { ...QUERY_DEFAULTS, ...putReadChatMessage, method: 'put' } satisfies Query<any>,
  putRxCancel: { ...QUERY_DEFAULTS, ...putRxCancel, method: 'put' } satisfies Query<any>,
  putSafetyPlan: { ...QUERY_DEFAULTS, ...putSafetyPlan, method: 'put' } satisfies Query<putSafetyPlan.Request>,
  putStartPendingVisit: { ...QUERY_DEFAULTS, ...putStartPendingVisit, method: 'put' } satisfies Query<any>,
  putUpdatePassword: { ...QUERY_DEFAULTS, ...putUpdatePassword, method: 'put' } satisfies Query<any>,
  putVerificationSend: { ...QUERY_DEFAULTS, ...putVerificationSend, method: 'put' } satisfies Query<any>,
  putVisitAssessment: { ...QUERY_DEFAULTS, ...putVisitAssessment, method: 'put' } satisfies Query<any>,
  putVisitDischarge: { ...QUERY_DEFAULTS, ...putVisitDischarge, method: 'put' } satisfies Query<any>,
  putVisitDx: { ...QUERY_DEFAULTS, ...putVisitDx, method: 'put' } satisfies Query<any>,
  putVisitPharmacy: { ...QUERY_DEFAULTS, ...putVisitPharmacy, method: 'put' } satisfies Query<any>,
  putVisitReferral: { ...QUERY_DEFAULTS, ...putVisitReferral, method: 'put' } satisfies Query<any>,
  putVisit: { ...QUERY_DEFAULTS, ...putVisit, method: 'put' } satisfies Query<any>,
  postMedicationsV2: {
    ...QUERY_DEFAULTS,
    ...postMedicationsV2,
    method: 'post',
  } satisfies Query<postMedicationsV2.Request>,
  putMedicalHistoryV2: {
    ...QUERY_DEFAULTS,
    ...putMedicalHistoryV2,
    method: 'put',
  } satisfies Query<putMedicalHistoryV2.Request>,
  putMedicationsV2: { ...QUERY_DEFAULTS, ...putMedicationsV2, method: 'put' } satisfies Query<putMedicationsV2.Request>,
  getMemberVisitImageV2: {
    ...QUERY_DEFAULTS,
    ...getMemberVisitImageV2,
    method: 'get',
  } satisfies Query<getMemberVisitImageV2.Request>,
  getMemberVisitImagesV2: {
    ...QUERY_DEFAULTS,
    ...getMemberVisitImagesV2,
    method: 'get',
  } satisfies Query<getMemberVisitImagesV2.Request>,
  getMemberVisitThumbImageV2: {
    ...QUERY_DEFAULTS,
    ...getMemberVisitThumbImageV2,
    method: 'get',
  } satisfies Query<getMemberVisitThumbImageV2.Request>,
  deleteScheduleSlot: {
    ...QUERY_DEFAULTS,
    ...deleteScheduleSlot,
    method: 'delete',
  } satisfies Query<deleteScheduleSlot.Request>,
  getProviderSchedule: {
    ...QUERY_DEFAULTS,
    ...getProviderSchedule,
    method: 'get',
  } satisfies Query<getProviderSchedule.Request>,
  getProviderSchedules: {
    ...QUERY_DEFAULTS,
    ...getProviderSchedules,
    method: 'get',
  } satisfies Query<getProviderSchedules.Request>,
  postProviderSchedule: {
    ...QUERY_DEFAULTS,
    ...postProviderSchedule,
    method: 'post',
  } satisfies Query<postProviderSchedule.Request>,
  postScheduleSlot: {
    ...QUERY_DEFAULTS,
    ...postScheduleSlot,
    method: 'post',
  } satisfies Query<postScheduleSlot.Request>,
  putScheduleSlot: { ...QUERY_DEFAULTS, ...putScheduleSlot, method: 'put' } satisfies Query<putScheduleSlot.Request>,
} as const

export type ApiQueriesRequestTypes = {
  deleteAllergies: any
  deleteMedications: any
  getPossibleConditions: any
  postAllergies: any
  postFamilyHistory: any
  postMedications: postMedications.Request
  putAllergies: any
  putFamilyHistory: any
  putMedications: putMedications.Request
  putStudentInfo: any
  getPolicies: any
  getPolicy: any
  deleteVisitNote: any
  postVisitNote: any
  putVisitNote: any
  deleteVisitReferral: any
  getCancellationReasons: getCancellationReasons.Request
  getCareProtocolVersion: any
  getCareProtocols: any
  getChat: any
  getClientGroupServices: any
  getClient: any
  getForms: any
  getMbcScores: any
  getMe: any
  getMedication: any
  getMedication2: getMedication2.Request
  getMedications: any
  getMedications2: getMedications2.Request
  getMemberPrivateData: any
  getMemberServices: any
  getMemberVisitImage: getMemberVisitImage.Request
  getMemberVisitImages: getMemberVisitImages.Request
  getMemberVisitThumbImage: getMemberVisitThumbImage.Request
  getMember: any
  getPendingVisit: any
  getPendingVisits: any
  getPharmacy: any
  getPhoneCallToken: getPhoneCallToken.Request
  getPrescription: any
  getProviderAvailability: getProviderAvailability.Request
  getProviderDispenseUnits: any
  getProviderMembers: getProviderMembers.Request
  getProviderPastVisits: any
  getProviderPayment: any
  getProviderPayments: any
  getProviderQuestionnaireLatest: any
  getProviderQuestionnaire: any
  getProviderSchedulings: any
  getProviderVisit: any
  getProviderVisitsQueue: any
  getProvider: any
  getRxChangePharmacy: getRxChangePharmacy.Request
  getRxChangePrescriptionDetails: getRxChangePrescriptionDetails.Request
  getRxChanges: any
  getSearchDischargeInstructions: any
  getSearchIcdCodes: any
  getSearchPharmacies: any
  getStartVideoCallV2: any
  getTransmissionErrors: any
  getVisitEscalations: getVisitEscalations.Request
  getVisits: any
  getVisits2: any
  postAcknowledgeTransmissionErrors2: postAcknowledgeTransmissionErrors2.Request
  postAuthChallenge: postAuthChallenge.Request
  postChatMessage: any
  postConference: any
  postConferencesCallback: postConferencesCallback.Request
  postFillPrescriptions: any
  postFollowupVisit: any
  postForgotPassword: any
  postGenerateMfa: any
  postLogin: any
  postMemberPrivateData: any
  postRefreshToken: any
  postResendVerificationEmail: any
  postResetPassword: any
  postRx2: postRx2.Request
  postSupportTicket: any
  postVerifyMfa: postVerifyMfa.Request
  postVisitEscalation: postVisitEscalation.Request
  putChangeRequestApprove: any
  putChangeRequestDeny: any
  putClaimPendingVisit: any
  putCompleteVisit: any
  putPendingVisitStatus: any
  putPhoneVerify: any
  putProviderNotifications: any
  putProviderSchedule: any
  putProviderSchedulingsAccept: any
  putProviderSchedulingsCancel: any
  putProviderSchedulingsReject: any
  putProvider: any
  putPushRegistration: any
  putReadChatMessage: any
  putRxCancel: any
  putSafetyPlan: putSafetyPlan.Request
  putStartPendingVisit: any
  putUpdatePassword: any
  putVerificationSend: any
  putVisitAssessment: any
  putVisitDischarge: any
  putVisitDx: any
  putVisitPharmacy: any
  putVisitReferral: any
  putVisit: any
  postMedicationsV2: postMedicationsV2.Request
  putMedicalHistoryV2: putMedicalHistoryV2.Request
  putMedicationsV2: putMedicationsV2.Request
  getMemberVisitImageV2: getMemberVisitImageV2.Request
  getMemberVisitImagesV2: getMemberVisitImagesV2.Request
  getMemberVisitThumbImageV2: getMemberVisitThumbImageV2.Request
  deleteScheduleSlot: deleteScheduleSlot.Request
  getProviderSchedule: getProviderSchedule.Request
  getProviderSchedules: getProviderSchedules.Request
  postProviderSchedule: postProviderSchedule.Request
  postScheduleSlot: postScheduleSlot.Request
  putScheduleSlot: putScheduleSlot.Request
}

export type ApiQueriesSuccessfulResponseTypes = {
  deleteAllergies: any
  deleteMedications: any
  getPossibleConditions: any
  postAllergies: any
  postFamilyHistory: any
  postMedications: postMedications.SuccessfulResponse
  putAllergies: any
  putFamilyHistory: any
  putMedications: putMedications.SuccessfulResponse
  putStudentInfo: any
  getPolicies: any
  getPolicy: any
  deleteVisitNote: any
  postVisitNote: any
  putVisitNote: any
  deleteVisitReferral: any
  getCancellationReasons: getCancellationReasons.SuccessfulResponse
  getCareProtocolVersion: any
  getCareProtocols: any
  getChat: any
  getClientGroupServices: any
  getClient: any
  getForms: any
  getMbcScores: any
  getMe: any
  getMedication: any
  getMedication2: getMedication2.SuccessfulResponse
  getMedications: any
  getMedications2: getMedications2.SuccessfulResponse
  getMemberPrivateData: any
  getMemberServices: any
  getMemberVisitImage: getMemberVisitImage.SuccessfulResponse
  getMemberVisitImages: getMemberVisitImages.SuccessfulResponse
  getMemberVisitThumbImage: getMemberVisitThumbImage.SuccessfulResponse
  getMember: any
  getPendingVisit: any
  getPendingVisits: any
  getPharmacy: any
  getPhoneCallToken: getPhoneCallToken.SuccessfulResponse
  getPrescription: any
  getProviderAvailability: getProviderAvailability.SuccessfulResponse
  getProviderDispenseUnits: any
  getProviderMembers: getProviderMembers.SuccessfulResponse
  getProviderPastVisits: any
  getProviderPayment: any
  getProviderPayments: any
  getProviderQuestionnaireLatest: any
  getProviderQuestionnaire: any
  getProviderSchedulings: any
  getProviderVisit: any
  getProviderVisitsQueue: any
  getProvider: any
  getRxChangePharmacy: getRxChangePharmacy.SuccessfulResponse
  getRxChangePrescriptionDetails: getRxChangePrescriptionDetails.SuccessfulResponse
  getRxChanges: any
  getSearchDischargeInstructions: any
  getSearchIcdCodes: any
  getSearchPharmacies: any
  getStartVideoCallV2: any
  getTransmissionErrors: any
  getVisitEscalations: getVisitEscalations.SuccessfulResponse
  getVisits: any
  getVisits2: any
  postAcknowledgeTransmissionErrors2: postAcknowledgeTransmissionErrors2.SuccessfulResponse
  postAuthChallenge: postAuthChallenge.SuccessfulResponse
  postChatMessage: any
  postConference: any
  postConferencesCallback: postConferencesCallback.SuccessfulResponse
  postFillPrescriptions: any
  postFollowupVisit: any
  postForgotPassword: any
  postGenerateMfa: any
  postLogin: any
  postMemberPrivateData: any
  postRefreshToken: any
  postResendVerificationEmail: any
  postResetPassword: any
  postRx2: postRx2.SuccessfulResponse
  postSupportTicket: any
  postVerifyMfa: postVerifyMfa.SuccessfulResponse
  postVisitEscalation: postVisitEscalation.SuccessfulResponse
  putChangeRequestApprove: any
  putChangeRequestDeny: any
  putClaimPendingVisit: any
  putCompleteVisit: any
  putPendingVisitStatus: any
  putPhoneVerify: any
  putProviderNotifications: any
  putProviderSchedule: any
  putProviderSchedulingsAccept: any
  putProviderSchedulingsCancel: any
  putProviderSchedulingsReject: any
  putProvider: any
  putPushRegistration: any
  putReadChatMessage: any
  putRxCancel: any
  putSafetyPlan: putSafetyPlan.SuccessfulResponse
  putStartPendingVisit: any
  putUpdatePassword: any
  putVerificationSend: any
  putVisitAssessment: any
  putVisitDischarge: any
  putVisitDx: any
  putVisitPharmacy: any
  putVisitReferral: any
  putVisit: any
  postMedicationsV2: postMedicationsV2.SuccessfulResponse
  putMedicalHistoryV2: putMedicalHistoryV2.SuccessfulResponse
  putMedicationsV2: putMedicationsV2.SuccessfulResponse
  getMemberVisitImageV2: getMemberVisitImageV2.SuccessfulResponse
  getMemberVisitImagesV2: getMemberVisitImagesV2.SuccessfulResponse
  getMemberVisitThumbImageV2: getMemberVisitThumbImageV2.SuccessfulResponse
  deleteScheduleSlot: deleteScheduleSlot.SuccessfulResponse
  getProviderSchedule: getProviderSchedule.SuccessfulResponse
  getProviderSchedules: getProviderSchedules.SuccessfulResponse
  postProviderSchedule: postProviderSchedule.SuccessfulResponse
  postScheduleSlot: postScheduleSlot.SuccessfulResponse
  putScheduleSlot: putScheduleSlot.SuccessfulResponse
}

export default ApiQueries
