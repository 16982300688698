import { InferType, object, string } from 'yup'

import { MedicationSchema } from 'schemas/MedicalHistory'
import { Medication, MedicationV2 } from 'types/PrivateData/MedicalHistory/Medication'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  memberId: string().required(),
  medicationId: string().required(),
  medication: MedicationSchema.required(),
})

export type Request = InferType<typeof Schema>

export const url = ({ memberId, medicationId }) => `/v1/history/personal/${memberId}/medications/${medicationId}`

export const getBody = (params: Request) => {
  const medication = MedicationSchema.cast(params.medication, { stripUnknown: true })

  return { medication }
}

export type SuccessfulResponse = {
  medications: (Medication | MedicationV2)[]
}
